import React, { Component } from 'react';
import './App.css';
import 'bulma';
import ConfigOptionInput from './components/ConfigOptionInput';
import update from 'immutability-helper';
import { AppContext } from './context';
import Textarea from 'react-textarea-autosize';
import netlifyIdentity from 'netlify-identity-widget';
import { loginUser, logoutUser } from './lib/identityActions';
import ReactGA from 'react-ga';

const yaml = require('js-yaml');
const config = require('./enableton-config.json');


ReactGA.initialize(process.env.REACT_APP_GA_ID);
const urlBase = process.env.REACT_APP_API_URL_BASE ? process.env.REACT_APP_API_URL_BASE : 'http://localhost:5000/';

const controlledConfig = [
    's3Bucket',
    'outputFilename',
    'importGroups',
    'songs',
    '',
];

export default class App extends Component {
    constructor(props) {
        super(props);

        const yaml = '';
        const json = '';
        this.state = {
            yaml,
            json,
            config,
            controlledConfig,
            currentTab: 'form',
            processModalIsOpen: false,

            outputFilename: '',
            isAuthenticated: false,
            user: null,
        };

        this.yaml = React.createRef();
        this.json = React.createRef();
    }

    componentDidMount = () => {
        // fetch('https://s3.amazonaws.com/enableton-dev/mercyhill-dev.als')
        // .then(resp => resp.json())
        // .then(data => this.setState(data));
        const savedConfig = JSON.parse(localStorage.getItem('config'));

        if (savedConfig) {
            this.setState({
                config: savedConfig,
            });
        }

        const user = localStorage.getItem("currentEnabletonUser");
        if (user) {
            this.setState({ user: JSON.parse(user) });
        } else {
            loginUser();
        }
        netlifyIdentity.on("login", (user) => this.setState({ user }, loginUser()));
        netlifyIdentity.on("logout", (user) => this.setState({ user: null }, logoutUser()));

        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    saveState = () => {
        localStorage.setItem('config', JSON.stringify(this.state.config));
    }

    componentWillUpdate = () => {

    }

    componentDidUpdate = (prevState) => {
        if (yaml.safeDump(this.state.config) !== this.state.yaml) {
            this.setState({
                yaml: yaml.safeDump(this.state.config),
            });
        }
        if (JSON.stringify(this.state.config) !== this.state.json) {
            this.setState({
                json: JSON.stringify(this.state.config),
            });
        }
        this.saveState();
    }

    getPath = (obj, path) => {
        var result = obj;
        path.forEach(key => result = result[key]);
        return result;
    };

    setPath = (obj, path, value) => {
        if (path.length > 1) {
            this.setPath(obj[path[0]], path.slice(1), value);
        } else {
            obj[path[0]] = value;
        }
    };

    handleInputChange = (e, path) => {
        // const name = e.target.name;
        if (path === undefined) {
            path = [e.target.name];
        }
        const value = e.target.value;
        const checked = e.target.checked;
        // path = [].concat(path === undefined ? [] : path, name);
        this.setState((previousState) => {
            const newState = { ...previousState };
            this.setPath(newState.config, path, typeof this.getPath(newState.config, path) == 'boolean' ? checked : value);
            return newState;
        });
    }

    handleArrayInputChange = (i, e) => {
        const name = e.target.name;
        const value = e.target.value;

        if (i === this.state.config[name].length - 1) {
            if (value !== '') {
                this.setState((previousState) => update(previousState, {
                    config: {
                        [name]: { $push: [''] }
                    }
                }));

            }
        }
        this.setState((previousState) => {
            return update(previousState, {
                config: {
                    [name]: { $splice: [[i, 1, value]] }
                }
            })
        });
    }

    handleArrayKeyDown = (i, e) => {
        const name = e.target.name;
        if (e.keyCode === 8) {
            if (this.state.config[name][i] === '') {
                this.setState((previousState) => update(previousState, {
                    config: {
                        [name]: { $splice: [[i, 1]] }
                    }
                }));
            }
        }
    }


    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            processModalIsOpen: true,
        });

        console.log(this.state.config);

        fetch(urlBase + 'process', {
            method: 'POST',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.state.config),
        })
            // .then(response => response.json())
            .then(response => response.text())
            .then((filename) => {
                // console.log(filename);
                this.setState({
                    outputFilename: filename,
                });
            });
    }

    closeModal = () => {
        this.setState({
            processModalIsOpen: false,
            outputFilename: null,
        });
    }

    renderTabs = () => {
        return (
            <div className="tabs">
                <ul>
                    <li className={this.state.currentTab === 'form' ? 'is-active' : ''}><a onClick={() => { this.setState({ currentTab: 'form' }) }}>Form</a></li>
                    <li className={this.state.currentTab === 'yaml' ? 'is-active' : ''}><a onClick={() => { this.setState({ currentTab: 'yaml' }) }}>YAML</a></li>
                    <li className={this.state.currentTab === 'json' ? 'is-active' : ''}><a onClick={() => { this.setState({ currentTab: 'json' }) }}>JSON</a></li>
                </ul>
            </div>
        );
    }

    // login = () => {
    //     this.authenticate(() => {
    //         console.log(this.state.user);
    //     });
    // };

    renderForm = () => {
        return (
            <div className="level" >
                <form onSubmit={this.handleSubmit}>
                    <div className="columns">
                        <div className="column is-half">
                            {this.state.controlledConfig.map((optionName, i) => {
                                return (
                                    <div key={i} className="field">
                                        <ConfigOptionInput name={optionName} />
                                    </div>
                                );
                            })}
                        </div>
                        <div className="column is-half">
                            <p>Select from the options below</p>
                            {Object.keys(this.state.config)
                                .filter((optionName) => this.state.controlledConfig.indexOf(optionName) === -1)
                                .map((optionName, i) => {
                                    return (
                                        <div key={i} className="field">
                                            <ConfigOptionInput name={optionName} />
                                        </div>
                                    );
                                })}

                            {/* <div className="field">
                <label className="label">Subject</label>
                <div className="control">
                    <div className="select">
                        <select>
                            <option>Select dropdown</option>
                            <option>With options</option>
                        </select>
                    </div>
                </div>
            </div> */}

                            <div className="field is-grouped">
                                <div className="control">
                                    <button className="button is-link">Process</button>
                                </div>
                                {/* <div className="control">
                    <button className="button is-text">Cancel</button>
                </div> */}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    renderYAML = () => {
        return (
            <div className="tile is-ancestor">
                <div className="tile is-1">
                    <aside className="menu">
                        <p className="menu-label"></p>
                        <ul className="menu-list">
                            <li><a onClick={this.saveYAML}>Save</a></li>
                        </ul>
                    </aside>
                </div>
                <div className="tile is-11">
                    <Textarea
                        className="input"
                        minRows={3}
                        defaultValue={this.state.yaml}
                        inputRef={this.yaml}
                    ></Textarea>
                </div>
            </div>
        );
    }

    saveYAML = (e) => {
        console.log({ config: yaml.safeLoad(this.yaml.current.value) });
        this.setState({ config: yaml.safeLoad(this.yaml.current.value) });
        e.preventDefault();
    }

    renderJSON = () => {
        return (
            <div className="tile is-ancestor">
                <div className="tile is-1">
                    <aside className="menu">
                        <p className="menu-label"></p>
                        <ul className="menu-list">
                            <li><a onClick={this.saveJSON}>Save</a></li>
                        </ul>
                    </aside>
                </div>
                <div className="tile is-11">
                    <Textarea
                        className="input"
                        minRows={3}
                        defaultValue={this.state.json}
                        inputRef={this.json}
                    ></Textarea>
                </div>
            </div>
        );
    }

    saveJSON = (e) => {
        this.setState({ config: JSON.parse(this.json.current.value) });
        e.preventDefault();
    }

    renderContent() {
        if (!this.state.user) {
            loginUser();
            return (
                <div>
                    {/* Please login */}
                </div>
            );
        }
        return (
            <div className="container">
            {this.renderTabs()}

            {this.state.currentTab === 'form' ? this.renderForm() : ''}
            {this.state.currentTab === 'yaml' ? this.renderYAML() : ''}
            {this.state.currentTab === 'json' ? this.renderJSON() : ''}

            <div className={this.state.processModalIsOpen ? 'modal is-active' : 'modal'}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Processing...</p>
                        <button className="delete" aria-label="close" onClick={this.closeModal}></button>
                    </header>
                    <section className="modal-card-body">
                        <div className="level">
                            <progress className="progress is-small is-dark" max="100" value="100"></progress>
                        </div>
                        <p>You session {this.state.outputFilename ? 'has finished' : 'is'} processing. Sorry we don't have a progress indicator yet...</p>
                        <p style={{ textDecoration: this.state.outputFilename ? 'line-through' : 'none' }}>A link will magically appear when it's ready</p>
                        {this.state.outputFilename ?
                            <p>
                                Here it is now:&nbsp;
                            <a href={'https://s3.amazonaws.com/' + this.state.config.s3Bucket + '/' + this.state.outputFilename}>{this.state.outputFilename}</a>
                            </p>
                            : ''
                        }

                    </section>
                    <footer className="modal-card-foot">
                        <button className="button" onClick={this.closeModal}>Close</button>
                    </footer>
                </div>
            </div>
            </div>
        );
    }


    render() {
        return (
            <AppContext.Provider value={{
                config: this.state.config,
                handleInputChange: this.handleInputChange,
                handleArrayInputChange: this.handleArrayInputChange,
                handleArrayKeyDown: this.handleArrayKeyDown,
            }}>
                <div className="App container">
                    <div className="level header">
                        <header className="App-header">
                            <h1>Enableton</h1>
                        </header>
                        <a className="button is-light" onClick={this.state.user ? logoutUser : loginUser}>
                            {this.state.user ? 'Logout' : 'Login'}
                        </a>
                    </div>

                    {this.renderContent()}

                </div>
            </AppContext.Provider>
        );
    }
}
