import netlifyIdentity from "netlify-identity-widget";

export function loginUser() {
    if (!netlifyIdentity || !netlifyIdentity.currentUser()) {
        return netlifyIdentity.open();
    }
    const {
        app_metadata, created_at, confirmed_at, email, id, user_metadata
    } = netlifyIdentity.currentUser();

    localStorage.setItem(
        "currentEnabletonUser",
        JSON.stringify({ ...app_metadata, created_at, confirmed_at, email, id, ...user_metadata })
    );
}

export function logoutUser() {
    console.log('logging out');
    netlifyIdentity.logout();
    localStorage.removeItem("currentEnabletonUser");
}
